import * as React from "react";

import { ClassValue, clsx } from "clsx";

function cn(...inputs: ClassValue[]) {
  return clsx(inputs);
}

import { Typography } from "components/ui/Typography";

interface ToastProps extends React.HTMLAttributes<HTMLDivElement> {
  visible: boolean;
}

function Toast({ visible, className, ...props }: ToastProps) {
  return <div {...props} />;
}

interface ToastTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {}

Toast.Title = function ToastTitle({ className, ...props }: ToastTitleProps) {
  return <p className={cn("text-sm font-medium", className)} {...props} />;
};

interface ToastDescriptionProps
  extends React.HTMLAttributes<HTMLParagraphElement> {}

Toast.Description = function ToastDescription({
  className,
  ...props
}: ToastDescriptionProps) {
  return <p className={cn("text-sm opacity-80", className)} {...props} />;
};

interface ToastOpts {
  title?: string;
  message: string;
  type?: "success" | "error" | "default";
  duration?: number;
}

export function toast(opts: ToastOpts) {
  const {
    title,
    message,
    type = "default",
    duration = 3000,
    position,
    ...rest
  } = opts;

  return import("react-hot-toast").then((toast) => {
    const hotToast = toast.default;
    return hotToast.custom(
      ({ visible }) => {
        return (
          <Toast
            visible={visible}
            style={{
              boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px",
              backgroundColor:
                type === "error"
                  ? "rgb(248 113 113)"
                  : type === "success"
                  ? "rgb(0 0 0)"
                  : "rgb(0 0 0)",
              padding: "24px",
              maxWidth: "500px",
            }}
            className="text-white"
          >
            <Typography variant="h6" color="text.white" marginBottom={5}>
              {title}
            </Typography>
            {message && (
              <Typography variant="body2" color="text.white">
                {message}
              </Typography>
            )}
          </Toast>
        );
      },
      { duration, ...rest, position: position }
    );
  });
}
