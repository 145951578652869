import React from "react";
import { Box } from "components/ui/box";
import { Typography } from "components/ui/Typography";
import { headerHeight } from "modules/non-protected-page/shared";
import {
  Container,
  ResponsiveText,
  headlineSizeConfig,
} from "modules/marketing-pages/front-page/shared";
import "react-multi-carousel/lib/styles.css";
import { IndexPageHeadTags } from "common/head-tags";
import Script from "next/script";
import { Features } from "modules/marketing-pages/front-page/features";
import Faq, {
  useFaqList,
} from "modules/marketing-pages/front-page/front-page-faq";
import { buildJsonLd } from "modules/marketing-pages/faq-to-json-ld";
import { PrimaryInput } from "components/input";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { toast } from "components/ui/toast/toast";

const Hero = React.memo(() => {
  return (
    <Box
      component="section"
      sx={{
        textAlign: "center",
        position: "relative",
        padding: "30px 0 0 0",

        padding: {
          xs: "30px 0 0 0",
          sm: "30px 0 0 0",
          md: "30px 0 0 0",
          lg: "30px 0 180px 0",
        },
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          position: "relative",
          marginTop: {
            xs: `24px`,
            sm: `24px`,
            md: `calc((100vh - ${headerHeight}px) / 10)`,
            xl: `calc((100vh - ${headerHeight}px) / 10)`,
          },
        }}
      >
        <Typography
          variant="h1"
          fontWeight={500}
          sx={{
            fontSize: headlineSizeConfig,
            "& > span": {
              fontSize: headlineSizeConfig,
            },
            color: "text.white",
            textAlign: "center",
            //  maxWidth: "800px",
            lineHeight: 1.2,
            width: {
              xs: "100%",
              sm: `100%`,
              md: `100%`,
              xl: `100%`,
              lg: "100%",
            },
          }}
        >
          Sora AI Video Generator
        </Typography>
        <ResponsiveText
          color="text.white"
          variant="body2"
          fontSize={20}
          textAlign={"center"}
          sx={{
            margin: "24px auto 0 auto",
            maxWidth: "600px",
            fontWeight: 400,
          }}
        >
          Turn your words into amazing videos in minutes with AI text to video
          generation powered by Sora. No video editing skills required.
        </ResponsiveText>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            columnGap: "12px",
            marginTop: "48px",
          }}
        >
          <PrimaryInput placeholder="Enter Your Email" size="small" />
          <OutlineGreyButton
            sx={{
              color: "text.white",
            }}
            onClick={(e) => {
              toast({
                title: "You have been added to the waitlist",
                message: `We will notify you when we are ready to launch`,
                position: "bottom-center",
                duration: 5000,
              });
            }}
          >
            Join The Wait List
          </OutlineGreyButton>
        </Box>
      </Container>
    </Box>
  );
});

const MainHero = React.memo(() => {
  return <Hero />;
});

const ScriptRenderer = React.memo(() => {
  const faqLists = useFaqList();
  return (
    <>
      <Script
        type="application/ld+json"
        strategy="beforeInteractive"
        id="sora-ai-faq"
        key="sora-ai-faq"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(buildJsonLd(faqLists)),
        }}
      />
    </>
  );
});

export default function IndexPage() {
  return (
    <>
      <IndexPageHeadTags />
      <ScriptRenderer />
      <Box
        sx={{
          background:
            "linear-gradient(177.9deg, #2f5ab6 0%, #5d25a3 50%, #d5c7e6 100%)",
        }}
      >
        <MainHero />
        <Features />
      </Box>

      <Faq />
    </>
  );
}
