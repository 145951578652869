import React from "react";
import FaqList from "modules/non-protected-page/faq-list";

export const useFaqList = () => {
  const faqLists = [
    {
      question: "What is Sora AI?",
      answer:
        "Sora AI is an advanced platform designed to convert text into captivating video content using AI, machine learning, and natural language processing technologies.",
    },
    {
      question: "How does Sora AI work?",
      answer:
        "Sora AI analyzes input text to identify key themes and sentiments, then selects appropriate visuals, animations, and voiceovers to create a video. It offers customization options for branding and style.",
    },
    {
      question: "What are the key features of Sora AI?",
      answer:
        "Key features include automated voiceovers with various accents and languages, a wide range of templates and animations, AI-driven editing, and custom branding options.",
    },
    {
      question: "What are the benefits of using Sora AI?",
      answer:
        "Benefits include efficiency in video production, accessibility to users without prior editing experience, high-quality output, and the ability to scale video production efforts.",
    },
    {
      question: "How do I create an OpenAI account for Sora AI?",
      answer:
        "To create an account, navigate to OpenAI’s website, click 'Sign up', provide your email and password, complete phone verification, and verify your email.",
    },
    {
      question: "How do you create videos on Sora AI?",
      answer:
        "Log into the OpenAI dashboard, access Sora AI, enter text descriptions for your video, use customization options to tailor the video, and then generate and edit the video.",
    },
    {
      question: "What are some use cases for Sora AI?",
      answer:
        "Use cases include content marketing, education, e-commerce, and storytelling.",
    },
    {
      question: "What are the limitations of Sora AI?",
      answer:
        "Limitations include limited customization options, dependence on text quality, voiceover limitations, visual and audio asset restrictions, a learning curve, internet connectivity dependency, varying processing times, cost considerations, privacy and data security concerns, and the need to adapt to platform updates.",
    },
    {
      question: "How do you get started with Sora AI?",
      answer:
        "New users can create an account on the platform and begin exploring its features through a user-friendly dashboard, with various pricing plans available.",
    },
    {
      question:
        "What are the best practices for converting text to video with Sora AI?",
      answer:
        "Focus on crafting effective scripts, choosing the right templates and customizations, and strategically incorporating AI-generated videos into your content strategy.",
    },
    {
      question: "How does Sora AI compare to other text-to-video tools?",
      answer:
        "Sora AI distinguishes itself with advanced AI capabilities, ease of use, and a comprehensive feature set, making it an attractive option for content creators.",
    },
    {
      question: "What is the future of AI in video production?",
      answer:
        "As AI advances, its role in video production will grow, with Sora AI leading the transformation towards more efficient, creative, and accessible content creation.",
    },
    {
      question:
        "Why is Sora AI considered an all-in-one solution for text-to-video conversion?",
      answer:
        "Sora AI enables effortless transformation of text into video, offering a comprehensive set of features and benefits that cater to a wide range of content creation needs.",
    },
  ];

  return faqLists;
};
const Faq = React.memo(() => {
  const faqLists = useFaqList();
  return <FaqList faqLists={faqLists} variant="secondary" />;
});

export default Faq;
